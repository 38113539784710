@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  .dark .prose {
    --tw-prose-body: #ffffff;
    --tw-prose-headings: #ffffff;
    --tw-prose-links: var(--primary);
    --tw-prose-bold: #ffffff;
    --tw-prose-code: #ffffff;
    --tw-prose-quotes: #a1a1aa;
  }

  .prose code {
    background-color: var(--muted);
    color: var(--foreground);
  }

  /* Typography styles */
  h1, h2, h3, h4, h5, h6, p, li, blockquote, td, th, a {
    color: var(--foreground);
  }

  h1 {
    @apply scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl;
  }

  h2 {
    @apply mt-10 scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0;
    border-color: var(--border);
  }

  h3 {
    @apply scroll-m-20 text-2xl font-semibold tracking-tight;
  }

  p {
    @apply leading-7 [&:not(:first-child)]:mt-0;
  }

  blockquote {
    @apply mt-6 border-l-2 pl-6 italic;
    border-color: var(--border);
    color: var(--muted-foreground);
  }

  ul {
    @apply my-6 ml-6 list-disc [&>li]:mt-2;
  }

  a {
    @apply font-medium underline underline-offset-4;
    color: var(--primary);
  }

  table {
    @apply w-full;
  }

  thead tr {
    @apply m-0 border-t p-0 even:bg-muted;
    border-color: var(--border);
  }

  th {
    @apply border px-4 py-2 text-left font-bold [&[align=center]]:text-center [&[align=right]]:text-right;
    border-color: var(--border);
  }

  tbody tr {
    @apply m-0 border-t p-0 even:bg-muted;
    border-color: var(--border);
  }

  td {
    @apply border px-4 py-2 text-left [&[align=center]]:text-center [&[align=right]]:text-right;
    border-color: var(--border);
  }

  .text-sidebar-item {
    color: var(--sidebar-item);
  }

  :root {
    font-size: 85%;
    /* Dark mode variables as default */
    --background: #121212;
    --foreground: #ffffff;

    --card: #1E1E1E;
    --card-foreground: #ffffff;

    --popover: #1E1E1E;
    --popover-foreground: #ffffff;

    --primary: #9593ff;
    --primary-foreground: #ffffff;

    --secondary: rgba(255, 255, 255, 0.04);
    --secondary-foreground: #ffffff;

    --background-rgb: 18, 18, 18;

    --muted: #1E1E1E;
    --muted-foreground: #a1a1aa;

    --sidebar-item: #a1a1aa;

    --accent: #27272a;
    --accent-foreground: #ffffff;

    --destructive: #ff7070;
    --destructive-foreground: #000000;

    --border: rgba(75, 85, 99, 0.3);
    --input: rgba(255, 255, 255, 0.1);
    --ring: #9593ff;

    --chart-1: 220 70% 50%;
    --chart-5: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-2: 340 75% 55%;

    --radius: 0.5rem;

    --sidebar: #1E1E1E;
    --sidebar-hover: #2B2B3C;
    --sidebar-secondary: #141412;
    --sidebar-secondary-hover: #323248;

    --muted: rgba(255, 255, 255, 0.04);

    /* Calendar Dark Mode as default */
    --calendar-background: rgba(0, 0, 0, 0.2);
    --calendar-border: rgba(255, 255, 255, 0.05);
    --calendar-text: #9ca3af;
    --calendar-time-gutter: #4b5563;
    --calendar-header-text: #d1d5db;
    --calendar-today: #63b3ed;
    --calendar-now-indicator: #ff6b6b;
    --calendar-off-range: #374151;
    --calendar-selection: rgba(66, 153, 225, 0.1);
    --calendar-grid-line: rgba(255, 255, 255, 0.05);

    /* Kanban Dark Mode as default */
    --kanban-to-start: rgba(239, 247, 253, 0.15);
    --kanban-in-progress: rgba(239, 247, 253, 0.15);
    --kanban-overdue: rgba(239, 247, 253, 0.15);
    --kanban-completed: rgba(239, 247, 253, 0.15);
  }

  .light {
    /* Light mode variables */
    --background: #FDFDFD;
    --foreground: #1e293b;

    --card: #ffffff;
    --card-foreground: #1e293b;

    --popover: #ffffff;
    --popover-foreground: #1e293b;

    --primary: #8b5cf6;
    --primary-foreground: #ffffff;

    --secondary: #7c3aed;
    --secondary-foreground: #ffffff;

    --background-rgb: 255, 255, 255;

    --muted: #f3f4f6;
    --muted-foreground: #1E1E1E;

    --sidebar-item: #D3D3D3;

    --accent: #f3f4f6;
    --accent-foreground: #1e293b;

    --destructive: #ef4444;
    --destructive-foreground: #ffffff;

    --border: rgba(255, 255, 255, 0.1);
    --input: #f1f5f9;
    --ring: #8b5cf6;

    --sidebar: #6d28d9;
    --sidebar-hover: #7c3aed;
    --sidebar-secondary: #8b5cf6;
    --sidebar-secondary-hover: #9333ea;

    --success: 142 76% 36%;

    --chart-1: 173 58% 39%;
    --chart-2: 12 76% 61%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    /* Calendar Light Mode */
    --calendar-background: rgba(255, 255, 255, 0.5);
    --calendar-border: rgba(255, 255, 255, 0.1);
    --calendar-text: #4a5568;
    --calendar-time-gutter: #718096;
    --calendar-header-text: #2D3748;
    --calendar-today: #4299e1;
    --calendar-now-indicator: #e53e3e;
    --calendar-off-range: #a0aec0;
    --calendar-selection: #ebf8ff;
    --calendar-grid-line: #edf2f7;

    /* Kanban Light Mode */
    --kanban-to-start: rgba(239, 247, 253, 0.1);
    --kanban-in-progress: rgba(252, 237, 252, 0.1);
    --kanban-overdue: rgba(255, 235, 238, 0.1);
    --kanban-completed: #f0f7f0;
  }
}

/* Base styles */
body {
  background-color: var(--background);
  color: var(--foreground);
}

/* Transitions */
* {
  transition: background-color 0.1s ease, border-color 0.1s ease, color 0.1s ease;
}

/* Card styles */
.card {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background-color: var(--card);
  border: 1px solid var(--border);
}

.light .card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  background-color: var(--card);
  border: 1px solid var(--border);
}

/* Calendar styles */
.rbc-calendar {
  @apply border-none bg-transparent;
}

.rbc-month-view,
.rbc-time-view {
  @apply border-none rounded-xl overflow-hidden;
  background-color: var(--calendar-background) !important;
  border: none !important;
}

/* Header styling */
.rbc-time-header {
  border: none !important;
  @apply bg-transparent rounded-t-xl overflow-hidden;
}

.rbc-time-header.rbc-overflowing {
  border: none !important;
}

.rbc-time-header-content {
  border: none !important;
  @apply bg-transparent;
}

.rbc-header {
  @apply flex flex-col items-center justify-center h-12 p-1 bg-transparent;
  border: none !important;
  color: var(--calendar-header-text) !important;
}

.rbc-header + .rbc-header {
  border: none !important;
}

.rbc-header span {
  @apply flex flex-col items-center gap-0.5 leading-none bg-transparent;
}

/* Time gutter styling */
.rbc-time-gutter {
  @apply text-sm;
  border: none !important;
  color: var(--calendar-time-gutter) !important;
  background: var(--calendar-background) !important;
}

.rbc-time-gutter .rbc-timeslot-group .rbc-time-slot {
  @apply font-medium text-sm;
  color: var(--calendar-text) !important;
}

/* Time slot styling */
.rbc-time-slot {
  border-top: none !important;
}

/* Grid lines */
.rbc-timeslot-group {
  border-bottom: 1px solid var(--calendar-grid-line) !important;
}

.rbc-time-content {
  border-top: none !important;
  @apply rounded-b-xl overflow-hidden;
}

.rbc-time-content > * > * {
  border-bottom: 1px solid var(--calendar-grid-line) !important;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid var(--calendar-grid-line) !important;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid var(--calendar-grid-line) !important;
}

.rbc-event-label {
  display: none !important;
}

/* Event styling */
.rbc-event {
  @apply min-h-6 leading-6 p-1 rounded-lg font-normal text-sm transition-all;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4) !important;
}

.rbc-event:hover {
  @apply scale-[1.02] opacity-90;
}

/* Current time indicator */
.rbc-current-time-indicator {
  background: var(--calendar-now-indicator) !important;
  @apply h-0.5;
}

/* Off-range cells */
.rbc-off-range-bg {
  @apply bg-[#1a1a1a];
}

.light .rbc-off-range-bg {
  @apply bg-[#f7fafc];
}

.rbc-off-range {
  color: var(--calendar-off-range) !important;
}

/* Selected and today cells */
.rbc-selected-cell {
  background: var(--calendar-selection) !important;
}

.rbc-today {
  background-color: rgba(var(--primary), 0.05);
}

.light .rbc-today {
  background-color: transparent;
}

.rbc-date-cell {
  @apply p-2 font-medium;
  color: var(--calendar-text) !important;
}

.rbc-date-cell.rbc-now {
  color: var(--calendar-today) !important;
  @apply font-semibold;
}

/* All-day cell */
.rbc-allday-cell {
  @apply h-8 bg-transparent;
  border: none !important;
}

/* Remove any remaining borders */
.rbc-time-view-resources .rbc-time-header-gutter {
  border: none !important;
}

/* Time view resource gutters */
.rbc-time-gutter .rbc-timeslot-group {
  border-bottom: 1px solid var(--calendar-grid-line) !important;
}

.rbc-time-view .rbc-time-gutter {
  border-right: none !important;
}

/* Toolbar responsiveness */
@media (max-width: 768px) {
  .rbc-toolbar {
    @apply flex-col items-stretch gap-2;
  }

  .rbc-toolbar-label {
    @apply text-center my-2;
  }
}

.white-calendar {
  color-scheme: light;
  /* For Webkit browsers */
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

/* Sidebar styles */
.sidebar {
  @apply bg-[var(--sidebar)] transition-all duration-300;
}

.sidebar {
  @apply border-r border-accent;
}

/* Kanban styles */
.kanban-column {
  @apply rounded-lg p-4;
}

.kanban-column.to-start {
  @apply bg-[var(--kanban-to-start)];
}

.kanban-column.in-progress {
  @apply bg-[var(--kanban-in-progress)];
}

.kanban-column.overdue {
  @apply bg-[var(--kanban-overdue)];
}

.kanban-column.completed {
  @apply bg-[var(--kanban-completed)];
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-muted;
}

::-webkit-scrollbar-thumb {
  @apply bg-accent rounded-full;
}

/* Calendar responsive styles */
@media (max-width: 768px) {
  .rbc-toolbar {
    @apply flex-col items-stretch gap-2;
  }

  .rbc-toolbar-label {
    @apply text-center my-2;
  }
}

.bg-login {
  font-size: 100%;  /* Override the root font size for login/register pages */
  background: url('/public/login-background.webp') center / cover no-repeat, var(--background);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* TipTap Editor Styles */
.ProseMirror {
  color: #1a1a1a !important;
}

.ProseMirror p {
  color: #1a1a1a !important;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3 {
  color: #1a1a1a !important;
}

.ProseMirror ul,
.ProseMirror ol,
.ProseMirror li {
  color: #1a1a1a !important;
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}

.ProseMirror h1 {
  font-size: 2rem;
  font-weight: bold;
}

.ProseMirror h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

.ProseMirror h3 {
  font-size: 1.25rem;
  font-weight: 600;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #666;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

/* Add this at the end of the file */
.bg-grid-gray-100 {
  background-image: linear-gradient(to right, rgba(128, 128, 128, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(128, 128, 128, 0.1) 1px, transparent 1px);
  background-size: 24px 24px;
}